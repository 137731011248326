import { APP_NAME } from '@/constants/app'
import { FFMPEG_EXE_PATH } from '@/constants/paths'
import fs from 'fs'
import html2canvas from 'html2canvas'
import FileSaver from 'file-saver'
const path = require('path')
const { execFileSync } = require('child_process')

export default {
  data() {
    return {
      isRecording: false,
      activeTab: null,
    }
  },
  computed: {
    date() {
      return this.$store.state.map.date
    },
  },
  methods: {
    capture() {
      return new Promise(async (resolve) => {
        let route = this.$router.currentRoute
        if (route.name !== 'map') {
          this.activeTab = route.name
          await this.$router.push('map')
          await new Promise((resolve) => setTimeout(resolve, 500))
        }
        // hide control UI on map
        this.$store.commit('ecoplot/SET_CAPTURE_MODE', true)
        // wait for all control on map to hide
        setTimeout(() => {
          html2canvas(document.getElementsByClassName('map-container')[0], { backgroundColor: null, useCORS: true }).then((canvas) => {
            canvas.toBlob(async (blob) => {
              // show control UI on map
              this.$store.commit('ecoplot/SET_CAPTURE_MODE', false)
              if (this.activeTab) {
                await this.$router.push(this.activeTab)
                await new Promise((resolve) => setTimeout(resolve, 500))
                this.activeTab = null
              }

              resolve(blob)
            })
          })
        }, 100)
      })
    },
    async record() {
      if (!this.isRecording) {
        // start
        this.isRecording = true

        try {
          let recordedChunks = []
          this.mediaStream = await navigator.mediaDevices.getDisplayMedia()
          this.mediaStream.addEventListener('inactive', () => {
            this.isRecording = false
          })
          let mediaRecorder = new MediaRecorder(this.mediaStream, { mimeType: 'video/webm; codecs=vp9' })
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data)
              let blob = new Blob(recordedChunks, { type: 'video/webm' })
              FileSaver.saveAs(blob, 'Ecoplot.webm')
            }
          }
          mediaRecorder.start()
        } catch (err) {
          console.error(err)
        }
      } else {
        // stop
        this.isRecording = false

        this.mediaStream.getTracks().forEach((track) => track.stop())
      }
    },
    async recordFormat(type) {
      if (!this.isRecording && (type == 'mp4' || type == 'webm')) {
        // start
        this.isRecording = true
        try {
          let recordedChunks = []
          this.mediaStream = await navigator.mediaDevices.getDisplayMedia()
          this.mediaStream.addEventListener('inactive', () => {
            this.isRecording = false
          })
          let mediaRecorder = new MediaRecorder(this.mediaStream, { mimeType: `video/webm; codecs=vp9` })
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data)
              let blob = new Blob(recordedChunks, { type: `video/${type}` })
              FileSaver.saveAs(blob, `Ecoplot.${type}`)
            }
          }
          mediaRecorder.start()
        } catch (err) {
          //iserclick cancel
          this.isRecording = false
        }
      } else {
        // stop
        this.isRecording = false
        this.mediaStream.getTracks().forEach((track) => track.stop())
      }
    },
    gifing() {
      return new Promise((resolve, reject) => {
        if (!this.date.length) {
          reject('No dates to loop')
          return
        }

        // turn on overlay
        this.$store.commit('ecoplot/SET_OVERLAY', true)

        // hide control UI on map
        this.$store.commit('ecoplot/SET_CAPTURE_MODE', true)

        // calculate rect
        let domRect = document.getElementsByClassName('map-list')[0].getBoundingClientRect()
        let { x, y, w, h } = { x: Math.ceil(domRect.left), y: Math.ceil(domRect.top), w: Math.floor(domRect.width), h: Math.floor(domRect.height) }

        // wait for all control on map to hide
        setTimeout(() => {
          this.record()
            .then((webm) => {
              let inputPath = path.join(FFMPEG_EXE_PATH, 'input.webm')
              fs.writeFileSync(inputPath, webm)

              execFileSync('ffmpeg.exe', ['-i', 'input.webm', '-vf', `crop=${w}:${h}:${x}:${y}`, '-y', 'output.gif'], { cwd: FFMPEG_EXE_PATH })

              let outputPath = path.join(FFMPEG_EXE_PATH, 'output.gif')
              let gif = fs.readFileSync(outputPath)

              resolve(gif)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              // show control UI on map
              this.$store.commit('ecoplot/SET_CAPTURE_MODE', false)

              // turn off overlay
              this.$store.commit('ecoplot/SET_OVERLAY', false)
            })
        }, 100)
      })
    },
    cancel() {
      this.cancelWalk(() => {
        this.isCancel = true
        this.stream.getTracks().forEach((track) => track.stop())
      })
    },
  },
}
