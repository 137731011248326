<template>
  <b-nav-item id="project-recording" :title="recordingStatus === 'recording' ? this.$t('stop_recording') : this.$t('project_recording')">
    <feather-icon size="21" icon="StopCircleIcon" v-if="recordingStatus === 'recording'" :style="{ color: '#82868b !important' }" />
    <feather-icon size="21" icon="VideoIcon" v-else :style="{ color: '#82868b !important' }" />
    <ExportConfigModal ref="exportConfig" v-model="fileFormat" @save="exportVideo" />
  </b-nav-item>
</template>

<script>
import ExportConfigModal from './ExportConfigModal.vue'
import CaptureMixin from '@/mixins/CaptureMixin.js'
// import GIF from 'gif.js'
import html2canvas from 'html2canvas'
// import FileSaver from 'file-saver'

export default {
  components: {
    ExportConfigModal,
  },
  mixins: [CaptureMixin],
  props: ['countClick'],
  data() {
    return {
      gif: null,
    }
  },
  computed: {
    recordingStatus: {
      get() {
        return this.$store.state.ecoplot.recordingStatus || 'stop'
      },
      set(val) {
        this.$store.commit('ecoplot/SET_RECORDING_STATUS', val)
      },
    },
    fileFormat: {
      get() {
        return this.$store.state.ecoplot.fileFormat
      },
      set(value) {
        this.$store.commit('ecoplot/SET_FILE_FORMAT_VIDEO', value)
      },
    },
  },
  watch: {
    countClick() {
      if (this.recordingStatus === 'recording') {
        this.exportVideo()
      } else {
        this.openSetting()
      }
    },
    recordingStatus(status) {
      this.$emit('recordingStatus', status)
    },
    isRecording(isRecording) {
      if (!isRecording) {
        this.recordingStatus = 'stop'
      }
    },
  },
  methods: {
    exportVideo() {
      if (this.fileFormat === 'gif') {
        this.$store.commit('ecoplot/SET_GIFING_STATUS', true)
        // this.startRecordingGif()
      } else {
        let self = this
        if (self.recordingStatus === 'stop') {
          self.recordingStatus = 'recording'
          if (!this.fileFormat) {
            this.fileFormat = 'webm'
          }
          self.recordFormat(this.fileFormat)
        } else {
          self.recordingStatus = 'stop'
          if (this.mediaStream) {
            this.mediaStream.getTracks().forEach((track) => track.stop())
          }
          this.stopRecordingGif()
        }
      }
    },
    openSetting() {
      this.$refs.exportConfig.open()
    },
    // Khởi tạo quá trình ghi GIF
    async startRecordingGif() {
      this.isRecording = true
      this.gif = new GIF({
        workers: 2,
        quality: 10,
      })
      let domElement = document.getElementById('map-list')
      if (!domElement) {
        domElement = document.querySelector('.content-body')
      }
      if (domElement) {
        await this.addNextFrameGif(domElement)
      }
    },

    // Thêm khung hình vào GIF
    async addNextFrameGif(domElement) {
      try {
        const canvas = await html2canvas(domElement, { backgroundColor: null, useCORS: true })
        const base64Image = canvas.toDataURL('image/png')
        // const image = new Image()
        // image.src = base64Image
        this.gif.addFrame(base64Image, { delay: 200 })
        // this.gif.addFrame(base64Image, { copy: true, delay: 200 })
        requestAnimationFrame(this.addNextFrameGif)
      } catch {}
    },

    // Dừng quá trình ghi GIF
    stopRecordingGif() {
      this.isRecording = false
      if (this.gif) {
        this.gif.render()
        this.gif.on('finished', (blob) => {
          // let blob = new Blob(blob, { type: `video/gif` })
          // FileSaver.saveAs(blob, `Ecoplot.gif`)
        })
      }
    },
  },
}
</script>

<style>
.export-dropdown .dropdown-menu {
  margin-top: 1px;
}
</style>
