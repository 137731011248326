<template>
  <div class="app-content content" :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]">
    <div class="content-overlay" :style="{ zIndex: overlay ? overlay.zIndex || 2000 : -1, opacity: overlay ? overlay.opacity || 0 : 0 }">
      <div v-for="(message, index) in overlay.content || []" :key="index">{{ message }}</div>
    </div>
    <b-modal :title="modal ? modal.title : null" v-model="modalShow" :body-class="'custom-body'" size="lg" centered>
      <template #modal-footer>
        <div class="progress-footer">
          <b-button variant="flat-secondary" size="sm" class="detail-button" @click="changeDetailMode">
            <feather-icon :icon="detailMode === 'fewer' ? 'ChevronDownIcon' : 'ChevronUpIcon'" class="mr-50" />
            <span class="align-middle">{{ detailMode === 'fewer' ? $t('more_details') : $t('fewer_details') }}</span>
          </b-button>
        </div>
        <div class="text-center" :style="{ width: '100%' }"><b-button variant="outline-primary" :disabled="disableOK" size="sm" @click.stop="closeModal"> OK </b-button></div>
      </template>
      <div class="detail-container custom-scrollbar" :style="{ maxHeight: detailMode === 'fewer' ? '58px' : '580px', 'overflow-y': detailMode === 'fewer' ? 'hidden' : 'auto', 'justify-content': detailMode === 'fewer' ? 'flex-end' : 'flex-start' }">
        <div v-for="(content, index) in (modal && modal.content) || []" :key="index" class="detail-item" :class="background[content.status || 'default']">
          <div class="text-primary text-content-description">
            <span class="skip-text">{{ content.description }}</span>
            <span v-if="content.size" style="margin-left: 5px; margin-top: 2px" class="badge badge-light-secondary"> {{ content.size.toFixed(3) }} MB </span>
          </div>
          <small class="text-secondary" style="margin-right: 10px; margin-left: 10px; line-height: 1">{{ detailMode === 'fewer' ? content.step : content.time || 'Loading' }}</small>
          <div v-b-tooltip.html="{ customClass: `my-tooltip-class my-tooltip-class-${detailMode}` }" :title="content.status === 'success' ? $t('success') : localeContent(content.info)" class="d-flex">
            <feather-icon :icon="icon[content.status || 'default']" size="20" :class="{ loading: !content.status }" />
          </div>
        </div>
      </div>
      <b-progress :value="percent" max="100" :animated="animated" :class="`progress-bar-${variant}`" :variant="variant" />
    </b-modal>
    <div class="header-navbar-shadow" />
    <div class="content-wrapper" :class="contentWidth === 'boxed' ? 'container p-0' : null">
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '../../../../@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '../../../../@core/app-config/useAppConfig'
import { BModal, BButton, BProgress, VBTooltip } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: { AppBreadcrumb, BModal, BButton, BProgress },
  directives: { 'b-tooltip': VBTooltip },
  setup(props, context) {
    const { routerTransition, contentWidth } = useAppConfig()

    const path = computed(() => context.root.$route.name)

    return {
      path,
      routerTransition,
      contentWidth,
    }
  },
  data() {
    return {
      detailMode: 'fewer',
      background: {
        default: 'bg-light-primary',
        success: 'bg-light-success',
        warning: 'bg-light-warning',
        error: 'bg-light-danger',
      },
      icon: {
        default: 'LoaderIcon',
        success: 'CheckCircleIcon',
        warning: 'AlertCircleIcon',
        error: 'AlertCircleIcon',
      },
      variant: 'primary',
      animated: true,
    }
  },
  mounted() {
    try {
      if (document.querySelectorAll("a[href='#/map']")[0]) {
        setTimeout(() => {
          try {
            document.querySelectorAll("a[href='#/map']")[0].addEventListener('click', this.reInitMinimap)
          } catch {}
        }, 0)
      }
    } catch {}
  },
  computed: {
    disableOK() {
      try {
        return !this.percent || (this.percent && this.percent < 100)
      } catch {}
      return false
    },
    overlay() {
      return this.$store.state.ecoplot.overlay
    },
    modal() {
      return this.$store.state.ecoplot.modal
    },
    percent() {
      return this.modal ? this.modal.percent || 0 : 0
    },
    minimapSetting() {
      return this.$store.state.settings.minimap
    },
    modalShow: {
      get() {
        if (this.modal !== null && this.modal.show === true) {
          if (document.querySelector('.modal.fade.show')) document.querySelector('.modal.fade.show').style.pointerEvents = 'none'
          return true
        }
        return false
      },
      set(modalShow) {
        if (modalShow === false) {
          this.$store.commit('ecoplot/SET_MODAL', { show: false })
        }
      },
    },
  },
  watch: {
    modal(modal) {
      let variant = 'primary'
      let percent = modal ? modal.percent || 0 : 0
      if (percent !== 100) variant = 'primary'
      else if (!modal || !modal.content || !modal.content.length) variant = 'primary'
      else {
        let errorCount = modal.content.filter((c) => c.status === 'error').length
        let warningCount = modal.content.filter((c) => c.status === 'warning').length
        if (errorCount) variant = 'danger'
        else if (warningCount) variant = 'warning'
        else variant = 'success'
      }
      let animated = true
      if (percent === 100) animated = false

      setTimeout(() => {
        this.variant = variant
        this.animated = animated
      }, 0)
    },
  },
  methods: {
    reInitMinimap() {
      this.$store.commit('settings/SET_COUNT_MINIMAP')
    },
    changeDetailMode() {
      if (this.detailMode === 'fewer') {
        this.detailMode = 'more'
      } else if (this.detailMode === 'more') {
        this.detailMode = 'fewer'
      }
    },
    closeModal() {
      this.$store.commit('ecoplot/SET_MODAL', { show: false })
    },
    localeContent(content) {
      let contentTemp = content
      try {
        try {
          contentTemp = contentTemp.replaceAll('Invalid csv format', this.$t('Invalid csv format'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('Skipped row', this.$t('Skipped row'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('Skipped column', this.$t('Skipped column'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('Skipped last column', this.$t('Skipped last column'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('Not support file extension', this.$t('Not support file extension'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('empty', this.$t('empty'))
        } catch {}
        try {
          contentTemp = contentTemp.replaceAll('cannot_open_this_file', this.$t('cannot_open_this_file'))
        } catch {}
      } catch {}
      return contentTemp
    },
  },
}
</script>

<style>
.detail-container .bg-light-success {
  background: rgba(57, 123, 255, 0.12) !important;
}
.custom-body.modal-body {
  color: inherit !important;
}

.my-tooltip-class .tooltip-inner {
  max-width: unset;
}
</style>

<style scoped lang="scss">
.content-overlay {
  cursor: wait !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.progress-footer {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.detail-button:hover,
.detail-button:focus {
  background-color: transparent !important;
}

.detail-container {
  overflow-y: auto;
  transition: max-height 0.25s;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.detail-item {
  display: flex;
  align-items: center;
  margin: 4px 0;
  min-height: 50px;
  padding: 0 1rem;
  border-radius: 0.357rem;
}
.badge {
  font-size: 70%;
}

.detail-item svg.loading {
  animation: loading 2s linear 0s infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss">
.skip-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-content-description {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: auto;
  min-width: 370px;
}
.my-tooltip-class-fewer {
  top: 1.7rem !important;
}
</style>
